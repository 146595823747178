.globeBackground {
  background: url("./back_globe.png");
  background-color: whitesmoke;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center !important;
  background-size: cover !important;
  /* opacity: 95%; */
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.banner {
  background: url("./adminHeader.png") no-repeat;
  background-size: cover;
}
.bannerPayer {
  background: url("./surveyHeader.png") no-repeat;
  background-size: cover;
}
input {
  outline: none;
}
