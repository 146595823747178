.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.step-button {
  width: 35px;
  height: 35px;
}

.step-item {
  z-index: 10;
  text-align: center;
}
.step-item hr {
  width: 35%;
  margin-left: 12px;
}
.activeStep {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: solid 2px whitesmoke;
  color: whitesmoke;
}
.activeSerial {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: solid 2px whitesmoke;
  color: whitesmoke;
}

.backgroundQuestion {
  /* background-color: #53809f; */
  /* background-image: linear-gradient(to right, #5c8eaf, #4b738e, #6398bc); */
  /* color: #a8d2ec */
}

.activeSerialQuestion {
  width: 35px;
  height: 35px;
  background-color: #0f4e7c;
  border: solid 1px #0f4e7c;
  color: whitesmoke;
}

.nextSteps {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: solid 1px;
  border-color: whitesmoke;
}
/* .btn-sml {
  margin-left: 0.5rem;
} */
.textWhitesmoke {
  color: darkslategray;
}
.stepperIcon {
  color: whitesmoke !important;
}
input[type="text"]:focus {
  outline: none;
}
input[type="number"] {
  outline: none;
  width: 5rem;
  height: 2rem;
  margin-top: 0.6rem;
}
body:focus {
  outline: none;
}
