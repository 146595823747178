.dashboardTable thead[class*="ant-table-thead"] th {
  /* background-color: #f0f1f3 !important; */
  width: 13rem !important;
  background-color: whitesmoke !important;
  /* background-color: #27a6a4 !important; */

  /* border-bottom: 0.2rem solid rgb(28, 31, 38) !important; */
  color: black !important;
}
.roundedRows {
  background-color: white;
  border-radius: 5px !important;
}

.roundedRows td:first-child {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.roundedRows td:last-child {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ant-table-row {
  background-color: white !important;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 20px;
}

.ant-table {
  background-color: whitesmoke !important;
}

.dashboardTable tbody[class*="ant-table-tbody"] td {
  border-bottom: 0.5rem solid whitesmoke !important;

  /* border-top: 0.2rem solid rgb(28, 31, 38) !important; */
  font-weight: 600;
  /* background-color: RGB(241, 231, 241) !important; */
}

.dashboardTable tbody[class*="ant-table-tbody"] tr {
  border-radius: 0.5rem !important;
  border-bottom: 0.5rem solid whitesmoke !important;

  /* border-top: 0.2rem solid rgb(28, 31, 38) !important; */
  font-weight: 600;
  /* background-color: RGB(241, 231, 241) !important; */
}

/* .ant-table-measure-row {
  display: none !important;
} */

.dashboardTable tr[class*="ant-table-measure-row"] td {
  border-bottom: 0rem !important;

  /* border-top: 0.2rem solid rgb(28, 31, 38) !important; */
  font-weight: 600;
  /* background-color: RGB(241, 231, 241) !important; */
}

.ant-table-column-sort {
  background-color: whitesmoke !important;
}

.ant-progress-outer {
  width: 80% !important;
}
.ant-progress-text {
  color: black !important;
}
.completeProgress {
  color: #7e92e9 !important;
}
.ant-table-column-sorters {
  margin-right: 0.2rem !important;
}
td.ant-table-column-sort {
  background-color: transparent !important;
}
.actionBorder {
  border-right: 1px solid grey !important;
}
.ant-table-cell-row-hover {
  background-color: white !important;
}

.ant-table-filter-trigger.active {
  color: #27a6a4 !important;
}
.active {
  color: #27a6a4 !important;
}
.ant-picker-focused {
  border-color: #27a6a4 !important;
}
.ant-picker-range:hover {
  border-color: #27a6a4 !important;
}
.ant-picker {
  border: 2px solid rgb(68, 84, 106) !important;
  border-radius: 4px !important;
}
.ant-picker:hover {
  border: 2px solid rgb(68, 84, 106) !important;
  border-radius: 4px !important;
}
.anticon-swap-right {
  color: rgb(68, 84, 106) !important;
}
.ant-picker-separator {
  padding-bottom: 1.37rem !important;
}
.anticon-calendar {
  color: rgb(68, 84, 106) !important;
}
.ant-tooltip-inner {
  background-color: #212429 !important;
}
.ant-tooltip-arrow-content {
  background-color: #212429 !important;
}
.ant-tooltip-placement-top {
  padding-bottom: 0.5rem !important;
}
.buttonBorder {
  margin-left: 10rem;
  background: transparent !important;
  border-color: transparent !important;
  border-bottom-style: solid;
  border-bottom-width: medium;
  border-bottom-color: rgb(237, 125, 49) !important;
  border-radius: 0.2rem;
  padding: 0.5rem;
  color: whitesmoke;
}
.outline {
  background-color: #27a6a4;
  /* opacity: 30%; */
  border-radius: 0.2rem;
  /* margin-left: 1.3rem; */
}
.ant-pagination-item {
  font-weight: 500;
  background: #27a6a4 !important;
  border-color: #27a6a4 !important;
  /* background-color: #27a6a4 !important;
  border-color: #27a6a4 !important; */
  color: whitesmoke !important;
}
.ant-pagination-item a {
  color: whitesmoke !important;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: rgb(1, 119, 119) !important;
  border-color: #27a6a4 !important;
  /* background-color: rgb(8, 76, 76) !important;
  border-color: rgb(8, 76, 76) !important; */
  color: whitesmoke !important;
}
.ant-pagination-item-active a {
  color: whitesmoke !important;
}
.ant-pagination-item-link {
  background-color: #27a6a4 !important;
  border: 1px solid #27a6a4 !important;
  /* background-color: #27a6a4 !important;
  border-color: #27a6a4 !important; */
}
.anticon-right {
  color: white !important;
}
.anticon-left {
  color: white !important;
}
@page {
  /* size: 210mm 297mm;  */
  /* Chrome sets own margins, we change these printer settings */
  margin: 0 0 0 0;
  size: 210mm 297mm;
}
@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}

thead[class*="ant-table-thead"] th {
  /* background-color: #f0f1f3 !important; */
  width: 13rem !important;
  background-color: rgb(68, 84, 106) !important;
  /* background-color: teal !important; */

  /* border-bottom: 0.2rem solid rgb(28, 31, 38) !important; */
  color: whitesmoke !important;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 20px;
}

.ant-table {
  background-color: rgb(255, 255, 255, 0) !important;
}
tbody[class*="ant-table-tbody"] td {
  border-bottom: 0.2rem solid rgb(28, 31, 38) !important;
  /* border-top: 0.2rem solid rgb(28, 31, 38) !important; */
  font-weight: 600;
  /* background-color: RGB(241, 231, 241) !important; */
}
