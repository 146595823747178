.center {
  text-align: center;
}
.initialPayerTableRows {
  background-color: rgb(227, 227, 227) !important;
}

.initialPayerTableRows tbody[class*="ant-table-tbody"] td {
  border-bottom: 0.2rem solid rgb(28, 31, 38) !important;
  /* padding: 0.5rem !important; */
  /* font-size: 0.8rem !important; */
  /* border-top: 0.2rem solid rgb(28, 31, 38) !important; */
  font-weight: 600;
  background-color: transparent !important;
}
.ant-btn-default:hover {
  border-color: #27a6a4 !important;
  color: #27a6a4 !important;
}
.ant-btn-default {
  border-color: #27a6a4 !important;
  color: #27a6a4 !important;
  border-radius: 0.2rem;
}
.ant-btn-primary[disabled] {
  background-color: lightgray !important;
  border-color: lightgray !important;
  color: black !important;
}
.ant-input:hover {
  border-color: #27a6a4 !important;
}
.ant-btn-primary {
  background-color: #27a6a4 !important;
  border-color: #27a6a4 !important;
  border-radius: 0.2rem;
}
.ant-btn-danger {
  background-color: white !important;
  border-color: red !important;
  color: red !important;
  border-radius: 0.2rem;
}
#footerOne {
  position: relative;
  /* margin-top: 0.5rem; */
  /* bottom: 0; */
  width: 100%;
  height: 50px;
  padding-left: 1.5rem;
  /* margin-bottom: 0; */
}
#footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: transparent !important;
  padding-left: 1.5rem;
  margin-top: 2.4rem;
}
#reviewquestioncard {
  background-color: white;
}
#summaryCard {
  background-color: rgba(245, 245, 245, 0.714);
  /* margin-inline: 2rem; */
}
#searchcriteria {
  background-color: white;
  border-radius: 5px;
  min-height: 10rem;
  max-height: 30rem;
  padding: 1rem;
}
#respondentcard {
  background-color: white;
  border-radius: 5px;
  min-height: 15rem;
  padding: 1rem;
}
.form-control {
  max-height: 2rem;
  font-size: small;
}
.ant-slider-track {
  background-color: cadetblue !important;
}
.ant-slider-handle {
  border-color: cadetblue !important;
}
.scrollable {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 18rem;

  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: #ddd solid 1px;
}
.scrollableFAQ {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 21.25rem;

  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: #ddd solid 1px;
}
.ant-form-item-label > label {
  color: darkslategrey !important;
}
::-webkit-input-placeholder {
  color: black;
}
::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.6rem;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-card-bordered {
  border: none !important;
}
.ant-card {
  background: transparent !important;
}
.ant-card-body {
  padding-bottom: 0 !important;
}
.ant-form-item {
  margin-bottom: 1rem !important;
}
.ant-form-item-with-help {
  margin-bottom: 0rem !important;
}
.ant-menu-item-selected {
  color: #27a6a4 !important;
}

.ant-menu-item-selected::after {
  border-bottom: 2px solid #27a6a4 !important;
}

.ant-menu-item-active:hover {
  color: #27a6a4 !important;
  border-bottom-color: #27a6a4 !important;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: darkslategray;
  cursor: pointer;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  color: darkslateblue;
}
.ant-select-arrow {
  color: darkslateblue;
}
.ant-select-selector:hover {
  border-color: #27a6a4 !important;
}
.ant-input-number:hover {
  border-color: #27a6a4 !important;
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #27a6a4 !important;
  box-shadow: none !important;
}
.ant-input-number-focused,
.ant-input-number:focus,
.ant-input-number:active {
  border-color: #27a6a4 !important;
  box-shadow: none !important;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: inherit;
}
.ant-input-disabled {
  border: 0;
}
.ant-input[disabled] {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
}
.ant-upload-list-item-name {
  color: #27a6a4 !important;
}
.anticon-paper-clip {
  color: gray !important;
}
.disableRanks {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #27a6a4 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #27a6a4 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #27a6a4 !important;
}
#disclaimer {
  padding-left: 2.25rem;
}

@media print {
  #divToPrint {
    background-color: whitesmoke !important;
  }
}
